import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {makeStyles} from '@mui/styles';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import FormattedNumberWithCurrency from '../../../components/formattedNumberWithCurrencies/formattedNumberWithCurrency';
import {ReactComponent as ArrowDown} from 'assets/img/arrow-down.svg';

import classNames from "classnames"

import {thirteenthGrey} from "assets/jss/main";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        width: '379px',
        padding: '24px 0px',
        margin: '0 !important',
        flexDirection: 'column',
        alignItems: 'flex-end',
        borderRadius: '8px !important',
        border: `1px solid ${thirteenthGrey}`,
        color: '#333',
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: 'normal',
        letterSpacing: '0.216px',
        background: '#FFF',
        boxShadow: '0px 4px 22px 0px rgba(0, 0, 0, 0.06)',
        '& .MuiCollapse-root': {
            width: '100%',
        },
    },
    summary: {
        display: 'flex',
        padding: '0px 30px',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'stretch',
        '& span:first-child': {
            fontWeight: 700,
        },
        '& .MuiAccordionSummary-content': {
            flexDirection: 'column',
            margin: 0,
        },
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
    },
    detail: {
        position: 'relative',
        padding: '0px 30px 12px 30px',
        '& > *': {
            float: 'right',
            letterSpacing: 'initial',
        },
    },
    retailerTotal: {
        marginTop: '24px',
        paddingTop: '24px',
        borderTop: `1px solid ${thirteenthGrey}`,
    },
    refund: {
        paddingTop: '24px',
        paddingBottom: '12px',
        borderTop: `1px solid ${thirteenthGrey}`,
    },
    totalWithShippingFees: {
        marginTop: '12px',
        paddingTop: '24px',
        fontWeight: 700,
        borderTop: `1px solid ${thirteenthGrey}`,
    },
    totalWithRefundAndShippingFeesTaxFree: {
        paddingBottom: 0,
    },
    totalWithShippingFeesTaxFree: {
        paddingBottom: '24px',
    },
    total: {
        marginTop: '12px',
        paddingTop: '24px',
        fontWeight: 700,
        borderTop: `1px solid ${thirteenthGrey}`,
    },
}));

export default function PriceDetails({ lineItems, currentOrganization, authorizations }) {
    const classes = useStyles();

    const order = lineItems?.[0]?.order;

    if (!Array.isArray(lineItems)) {
        return (<></>);
    }

    return (
        <Accordion
            className={classes.container}
            disableGutters={true}
        >
            <AccordionSummary
                expandIcon={<ArrowDown />}
                className={classes.summary}
            >
                <span>
                    <FormattedMessage id={'picking.order.show.label.total'} />
                    <FormattedNumberWithCurrency
                        value={order.totalWithRefundAndShippingFees}
                        currency={currentOrganization?.currency}
                    />
                </span>
                <span>
                    <FormattedMessage
                        id={'picking.order.show.label.items.total'}
                        values={{'number': order.lineItemsQuantity}}
                    />
                </span>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                <span className={classNames(classes.detail, classes.retailerTotal)}>
                    <FormattedMessage id={'picking.order.show.label.total_without_vat.with.adjustments'} />
                    <FormattedNumberWithCurrency
                        value={order.retailerTotal}
                        currency={currentOrganization?.currency}
                    />
                </span>
                {(order.totalAdjustmentsNonSponsored < 0) && (
                    <span className={classes.detail}>
                        <FormattedMessage id={'picking.order.show.label.total.adjustments.non.sponsored'} />
                        <FormattedNumberWithCurrency
                            value={order.totalAdjustmentsNonSponsored}
                            currency={currentOrganization?.currency}
                        />
                    </span>
                )}
                {(order.totalAdjustmentsSponsored < 0) && (
                    <span className={classes.detail}>
                        <FormattedMessage id={'picking.order.show.label.total.adjustments.sponsored'} />
                        <FormattedNumberWithCurrency
                            value={order.totalAdjustmentsSponsored	}
                            currency={currentOrganization?.currency}
                        />
                    </span>
                )}
                {(order.shippingFeesAmount > 0) && (
                    <span className={classes.detail}>
                        <FormattedMessage id={'picking.order.show.label.items.shippingFees'} />
                        <FormattedNumberWithCurrency
                            value={order.shippingFeesAmount}
                            currency={currentOrganization?.currency}
                        />
                    </span>
                )}
                {!!(order.refundAmount && order.refundAmount > 0) && (
                    <>
                        <span className={classNames(classes.detail, classes.totalWithShippingFees)}>
                            <FormattedMessage id={'picking.order.show.label.total_initial'} />
                            <FormattedNumberWithCurrency
                                value={order.totalWithShippingFees}
                                currency={currentOrganization?.currency}
                            />
                        </span>
                        <span className={classNames(classes.detail, classes.totalWithShippingFeesTaxFree)}>
                            <FormattedMessage id={'picking.order.show.label.total_without_vat_initial'} />
                            <FormattedNumberWithCurrency
                                value={order.totalWithShippingFeesTaxFree}
                                currency={currentOrganization?.currency}
                            />
                        </span>
                        <span className={classNames(classes.detail, classes.refund)}>
                            <FormattedMessage id={'picking.order.show.label.total.refund'} />
                            <FormattedNumberWithCurrency
                                value={-order.refundAmount}
                                currency={currentOrganization?.currency}
                            />
                        </span>
                    </>
                )}
                <span className={classNames(classes.detail, classes.total)}>
                    <FormattedMessage id={'picking.order.show.label.total'}/>
                    <FormattedNumberWithCurrency
                        value={order.totalWithRefundAndShippingFees}
                        currency={currentOrganization?.currency}
                    />
                </span>
                <span className={classNames(classes.detail, classes.totalWithRefundAndShippingFeesTaxFree)}>
                    <FormattedMessage id={'picking.order.show.label.total_without_vat'} />
                    <FormattedNumberWithCurrency
                        value={order.totalWithRefundAndShippingFeesTaxFree}
                        currency={currentOrganization?.currency}
                    />
                </span>
            </AccordionDetails>
        </Accordion>
    )
 }

 PriceDetails.propTypes = {
     lineItems: PropTypes.arrayOf(PropTypes.object),
     currentOrganization: PropTypes.object,
     authorizations: PropTypes.arrayOf(PropTypes.object).isRequired,
 };
