import React, {useContext} from 'react'
import PropTypes from "prop-types";

import {makeStyles} from "@mui/styles";

import {TableCell, TableRow} from "@mui/material";

import FormattedNumberWithCurrency
    from "components/formattedNumberWithCurrencies/formattedNumberWithCurrency";

import {lighterBlack} from "assets/jss/main";

import {OrderContext} from "contexts/OrderContext";
import {FormattedMessage} from "react-intl";

const useStyle = makeStyles({
    adjustment: {
        borderTop: 'none',
        borderBottom: "none",
        fontWeight: 'bold',
        padding: '8px 16px',
    },
    adjustmentTotal: {
        borderTop: 'none',
        borderBottom: "none",
        fontSize: "16px",
        padding: '8px 16px',
    },
    adjustmentLabel: {
        color: lighterBlack,
    },
})

export default function LineItemAdjustmentRow({label, amount, className, isSponsored}) {
    const classes = useStyle();

    const { currentOrganization } = useContext(OrderContext);

    return (
        <TableRow className={className}>
            <TableCell className={classes.adjustment}/>
            <TableCell className={classes.adjustment}/>
            <TableCell className={classes.adjustment}>
                {isSponsored ? (
                    <FormattedMessage id={`picking.order.shipments.header.promotion.sponsored`} />
                ) : (
                    <FormattedMessage id={`picking.order.shipments.header.promotion`} />
                )}
                <span className={classes.adjustmentLabel}>
                    {label}
                </span>
            </TableCell>
            <TableCell className={classes.adjustment}/>
            <TableCell className={classes.adjustmentTotal}>
                <b>
                    <FormattedNumberWithCurrency
                        value={amount / 100}
                        currency={currentOrganization?.currency}
                    />
                </b>
            </TableCell>
            <TableCell className={classes.adjustment}/>
            <TableCell className={classes.adjustment}/>
            <TableCell className={classes.adjustment}/>
            <TableCell className={classes.adjustment}/>
        </TableRow>
    )
}

LineItemAdjustmentRow.defaultProps = {
    label: '',
    amount: 0,
    className: '',
}

LineItemAdjustmentRow.propTypes = {
    label: PropTypes.string,
    amount: PropTypes.number,
    className: PropTypes.string,
}
