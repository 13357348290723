import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import Grid from "@mui/material/Grid";

import DragAndDropIcon from "components/icons/DragAndDropIcon";
import ItemName from "components/List/components/ItemName";
import Date from "components/List/components/Date";
import Hour from "components/List/components/Hour";
import IconButton from "components/button/IconButton";
import EditIcon from "components/icons/EditIcon";
import TrashIcon from "components/icons/TrashIcon";
import StyledLine from "components/List/components/StyledLine";
import {ListContext} from "components/List/context/ListContext";

import {getTranslation} from "domain/helpers/translations";

import {greyishBlue500} from "assets/jss/main";

ItemLine.propTypes = {
    optionValue: PropTypes.object.isRequired
};

function ItemLine({optionValue, ...props}) {
    const {
        openCreateDialog,
        openDeleteDialog,
    } = useContext(ListContext)

    return (
        <StyledLine
            justifyContent={"space-between"}
            alignItems={"center"}
            {...props}
        >
            <Grid
                item
                container
                xs={6}
                columnSpacing={2}
                flexWrap={"nowrap"}
            >
                <Grid
                    item
                    alignSelf={"center"}
                    display={"flex"}
                >
                    <DragAndDropIcon />
                </Grid>
                <Grid item zeroMinWidth>
                    <ItemName label={getTranslation(optionValue).name} />
                </Grid>
            </Grid>
            <Grid
                item
                container
                xs={6}
                columnSpacing={2}
                justifyContent={"flex-end"}
                alignItems={"center"}
                flexWrap={"nowrap"}
            >
                <Grid item sx={{ width: '100px' }}>
                    <Date date={optionValue.updatedAt} />
                </Grid>
                <Grid item sx={{ width: '60px' }}>
                    <Hour date={optionValue.updatedAt} />
                </Grid>
                <Grid
                    item
                    container
                    justifyContent={"flex-end"}
                    sx={{ width: '80px' }}
                >
                    <Grid item>
                        <IconButton onClick={() => openCreateDialog(optionValue)}>
                            <EditIcon />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={() => openDeleteDialog(optionValue)}>
                            <TrashIcon
                                color={greyishBlue500}
                                size={16}
                            />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </StyledLine>
    );
}

export default ItemLine;
