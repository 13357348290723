import React, {Component} from "react";
import PropTypes from "prop-types";
import {FormattedMessage, injectIntl} from "react-intl";

// @mui/material components
import {withStyles} from "@mui/styles";
import {Avatar, Table, TableBody, TableCell, TableHead, TableRow, Tooltip} from "@mui/material";

// @mui/icons-material
import {MonetizationOn} from "@mui/icons-material";

// core components
import CustomDate from 'components/date/date';
import ShippingIcon from "../components/shippingIcon";

// styles
import tableStyle from "assets/jss/views/picking/order/tableStyle";
import {primaryColor} from "assets/jss/main";

// helpers
import {capitalize} from "utils/capitalize";
import FormattedNumberWithCurrency from "../../../components/formattedNumberWithCurrencies/formattedNumberWithCurrency";
import {Authorizations, hasAuthorization} from "../../../utils/authorizations";
import {connect} from "react-redux";
import ShippingBadge from "../../../components/ShippingBadge";

class OrderTable extends Component {

    checkIfRunnerExist = (lineItems) => {
        for (let i = 0; i < lineItems.length; i++) {
            if (lineItems[i].runner !== null) {
                return true
            }
        }
        return false;
    };

    renderRunnerAvatar = (runner, key) => {
        const {classes} = this.props;
        let RunnerFullName = runner && runner.user.fullName;
        let runnerArray = runner && RunnerFullName.split(" ");

        return (
            runner.user.picture ?
                <img
                    src={runner.user.picture}
                    className={classes.image}
                    alt={""}
                    key={key}
                    style={{position: 'relative', zIndex: 20 - key, backgroundColor: primaryColor}}
                /> :
                <Avatar component={"span"} className={classes.imgPlaceholder} key={key}
                        style={{position: 'relative', zIndex: 20 - key, backgroundColor: primaryColor}}>
                    {capitalize(runnerArray[0].charAt(0))+capitalize(runnerArray[1].charAt(0))}
                </Avatar>
        );
    };

    renderRunner = (lineItems) => {
        const {classes} = this.props;

        let runnerList = [];

        if (this.checkIfRunnerExist(lineItems)) {
            return (
                <div className={classes.runnerList}>
                    {lineItems.map((item, key) => {
                        if (item.runner !== null) {
                            if (!runnerList.includes(item.runner["@id"])) {
                                runnerList.push(item.runner["@id"]);
                                return this.renderRunnerAvatar(item.runner, key);
                            }
                        }

                        return null;
                    })}
                </div>
            );
        } else {
            return (
                <div className={classes.emptyRunner}>---</div>
            )
        }
    }

    renderShippingIcons(orderShippingMethods, item) {
        let shippingIcons = [];
        const hasGiftWrapping = item.lineItems.some(item => item.giftWrapping === true);

        /* orders can have multiple shippingMethods
         see -> App\Entity\Order::getShippingMethods */
        for (const orderShippingMethod in orderShippingMethods) {
            shippingIcons.push(
                <div key={orderShippingMethod}>
                    <ShippingBadge enabled={hasGiftWrapping}>
                        <ShippingIcon
                            shippingMethodName={orderShippingMethod}
                            organizationShippingMethods={this.props.organizationShippingMethods}
                        />
                    </ShippingBadge>
                </div>
            );
        }

        return shippingIcons
    }

    render() {
        const {classes, tableHead, tableData, tableHeaderColor, intl} = this.props;

        return (
            <div className={classes.tableResponsive}>
                <Table className={classes.table}>
                    {tableHead !== undefined ? (
                        <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
                            <TableRow>
                                {tableHead.map((prop, key) => {
                                    return (
                                        <TableCell
                                            className={classes.tableCell + " " + classes.tableHeadCell}
                                            key={key}
                                        >{prop}</TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                    ) : null}
                    {(tableData && tableData.length) ?
                        <TableBody>
                            {tableData.map((item, key) => {
                                return (
                                    <TableRow
                                        hover
                                        key={key}
                                        className={classes.tableRow}
                                        onClick={(e) => {
                                            const url = `/picking/orders/show/${encodeURIComponent(item['@id'])}`;
                                            if (e.metaKey || e.ctrlKey) {
                                                window.open(url, '_blank');
                                            } else {
                                                this.props.history.push(url);
                                            }
                                        }}>
                                        <TableCell className={classes.tableCell}>
                                            <div>
                                                #{item["externalId"]}
                                            </div>
                                            <CustomDate date={item["createdAt"]} />
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <div>
                                                <b>
                                                    {
                                                        capitalize(item["customerShippingFirstname"]|| item["customerBillingFirstname"])
                                                        + " " +
                                                        capitalize(item["customerShippingLastname"] || item["customerBillingLastname"])
                                                    }
                                                </b>
                                            </div>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <div className={classes.shippingMethods}>
                                                {this.renderShippingIcons(item.shippingMethods, item)}
                                            </div>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <div><FormattedNumberWithCurrency value={item["retailerTotal"]} currency={this.props.currentOrganization?.currency} /></div>
                                            {item["retailerTotal"] !== item["total"] && (
                                              <div className={classes.lineIcon}>
                                                <i><FormattedNumberWithCurrency value={item["total"]} currency={this.props.currentOrganization?.currency} /></i>
                                                <Tooltip
                                                  title={intl.formatMessage({id: "picking.order.list.table.header.totalPayed"})}
                                                  className={classes.processingLoader}
                                                >
                                                  <MonetizationOn className={classes.lineIconIcon}/>
                                                </Tooltip>
                                              </div>
                                            )}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <div>{item["lineItemsQuantity"]}</div>
                                        </TableCell>
                                        {!hasAuthorization(this.props.authorizations, Authorizations.PICKING_RETAILER) &&
                                            <TableCell className={classes.tableCell}>
                                            {this.renderRunner(item.lineItems)}
                                            </TableCell>
                                        }
                                    </TableRow>
                                );
                            })}
                        </TableBody> :
                        <TableBody>
                            {(tableData) &&
                            <TableRow>
                                <TableCell colSpan={7} className={classes.tableCellNoResult}>
                                    <FormattedMessage id={"picking.dashboard.table.no_result"}/>
                                </TableCell>
                            </TableRow>
                            }
                        </TableBody>
                    }
                </Table>
            </div>
        );
    }
}

OrderTable.defaultProps = {
    tableHeaderColor: "gray",
    shippingMethods: [],
};

OrderTable.propTypes = {
    classes: PropTypes.object.isRequired,
    tableHeaderColor: PropTypes.oneOf([
        "warning",
        "primary",
        "danger",
        "success",
        "info",
        "rose",
        "gray"
    ]),
    tableHead: PropTypes.arrayOf(PropTypes.object),
    shippingMethods: PropTypes.arrayOf(PropTypes.object)
};

const mapStateToProps = state => {
    const {authorizations} = state.authentication;
    const currentOrganization = state.currentOrganization.retrieved ?? null;

    return {authorizations, currentOrganization};
};

export default connect(
    mapStateToProps,
    null
)(withStyles(tableStyle)(injectIntl(OrderTable)));
