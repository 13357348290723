import React from 'react';
import PropTypes from 'prop-types';

import {createTheme, ThemeProvider} from "@mui/material/styles";

import {Badge} from "@mui/material";
import {CardGiftcard} from "@mui/icons-material";

ShippingBadge.propTypes = {
    content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    color: PropTypes.oneOf([
        'default',
        'success',
        'online',
        'warning',
        'error',
        'archived',
        'draft',
        'outline',
        'warning'
    ]),
    enabled: PropTypes.bool
};

const theme = createTheme({
    components: {
        MuiBadge: {
            styleOverrides: {
                badge: {
                    width: '24px',
                    height: '24px',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '4px',
                    marginTop: '4px',
                },
            },
        },
    },
});

function ShippingBadge({
                   content = null,
                   color = 'error',
                   enabled = true,
                   ...props
               }) {

    let badgeContent = <CardGiftcard style={{ fontSize: "16px", color: "white" }} />;

    if (null !== content) {
        badgeContent = content;
    }

    if (false === enabled) {
        return props.children;
    }

    return (
        <ThemeProvider theme={theme}>
            <Badge
                color={color}
                badgeContent={badgeContent}
            >
                {props.children}
            </Badge>
        </ThemeProvider>
    );
}

export default ShippingBadge;
