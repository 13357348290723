import {fetch} from "utils/dataAccess";

export async function create(variationId, mediaObjectId, position) {
    const values = {
        variation: variationId,
        mediaObject: mediaObjectId,
        position: !!position ? position : 0,
    }

    const response = await fetch(`/variation_media_objects`, {
        method: "POST",
        body: JSON.stringify(values),
    });

    return await response.json();
}


export async function convertMediaObjectsToVariationMediaObjects(variationId, variationPictures) {
    if (!variationPictures || !variationId) return;

    variationPictures = variationPictures.map((picture, index) => ({
        ...picture,
        position: index
    }))

    // remove already existing variationMediaObject
    const mediaObjects = variationPictures.filter((pic) => pic['@context'] === '/contexts/MediaObject');

    for (const mediaObject of mediaObjects) {
        const variationMediaObject = await create(
            variationId,
            mediaObject['@id'],
            mediaObject.position
        );

        // replace mediaObject by his new variationMediaObject
        const mediaObjectIndex = variationPictures.findIndex((picture) => picture['@id'] === mediaObject['@id']);

        if (mediaObjectIndex !== -1) {
            variationPictures.splice(mediaObjectIndex, 1, variationMediaObject)
        }
    }

    return variationPictures;
}
