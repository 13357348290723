import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import VariationResume from "./components/VariationResume";
import VariationAccordion from "./components/VariationAccordion";

import {blue25, blueColor, greyishBlue100} from "assets/jss/main";

import {ProductShowContext} from "../../context/ProductShowContext";

import listOption from "api/option/list";

import {getTranslation} from "domain/helpers/translations";

import {getFirstProductImage} from "utils/getFirstProductImage";

VariationListing.propTypes = {
    variations: PropTypes.arrayOf(
        PropTypes.shape({
            retailerOptionValues: PropTypes.array,
            pictures: PropTypes.array,
            stock: PropTypes.number,
            sku: PropTypes.string
        })
    )
};

function VariationListing({variations}) {
    if (!variations) {
        return null;
    }

    const [groupedVariations, setGroupedVariation] = useState([]);

    const {switchVariation, selectedVariation} = useContext(ProductShowContext);

    useEffect(() => {
        listOption().then((options) => {
            setGroupedVariation(buildNestedStructure(options, variations));
        });
    }, [variations]);

    function sortRetailerOptionValueByOptionPosition(retailerOptionValues) {
        return retailerOptionValues.sort((a, b) => {
            return a.option.position - b.option.position;
        })
    }

    // do not ask me why pls
    // maybe do this in php
    function buildNestedStructure(options, variations) {
        const result = {};

        variations.forEach(variation => {
            let currentLevel = result;

            if (!variation.retailerOptionValues || variation.retailerOptionValues.length === 0) {
                const key = 'no-options';

                if (!currentLevel[key]) {
                    currentLevel[key] = {
                        optionName: 'No options',
                        value: 'No value',
                        variations: [],
                        count: 0,
                        ...variation
                    };
                }
                currentLevel[key].count++;
                currentLevel[key].variations.push(variation);
            } else {
                const sortedRetailerOptionValues = sortRetailerOptionValueByOptionPosition(variation.retailerOptionValues);
                sortedRetailerOptionValues.forEach((retailerOptionValue, index) => {
                    const optionName = getTranslation(retailerOptionValue.option).name;
                    const retailerOptionValueName = getTranslation(retailerOptionValue).name;

                    const key = `${optionName}-${retailerOptionValueName}`;

                    if (index === (sortedRetailerOptionValues.length - 1)) {
                        if (!currentLevel[key]) {
                            currentLevel[key] = {
                                optionName: optionName,
                                value: retailerOptionValueName,
                                variations: [],
                                count: 0,
                                ...variation
                            };
                        }
                        currentLevel[key].count++;
                        currentLevel[key].variations.push(variation);
                    } else {
                        if (!currentLevel[key]) {
                            currentLevel[key] = {
                                optionName: optionName,
                                value: retailerOptionValueName,
                                variants: [],
                                count: 0,
                                ...variation
                            };
                        }
                        currentLevel[key].count++;
                        currentLevel = currentLevel[key].variants;
                    }
                });
            }
        });

        return result;
    }

    const renderVariants = (data) => {
        if (!data) return null;

        return Object.values(data).map((item, index) => (
            <div
                key={index}
                style={{ padding: '0px 0px 0px 24px' }}
            >
                {item.variants ? (
                    <VariationAccordion
                        retailerOptionValue={item.value}
                        optionName={item.optionName}
                        image={getFirstProductImage(item.pictures)}
                        variationCount={item.count}
                        open={Object.values(item.variants).some(obj => obj.id === selectedVariation?.id)}
                    >
                        {renderVariants(item.variants)}
                    </VariationAccordion>
                ) : (
                    Object.values(item.variations).map((variation, index) => (
                        <div
                            key={index}
                            onClick={() => switchVariation(variation.id)}
                            style={{
                                padding: '8px 8px 12px 24px',
                                cursor: 'pointer',
                                ...selectedVariation?.id === variation.id && {
                                    border: `1px solid ${blueColor}`,
                                    borderRadius: '4px',
                                    backgroundColor: blue25
                                }
                            }}
                        >
                            <VariationResume
                                image={getFirstProductImage(variation.pictures)}
                                stock={variation.stock}
                                sku={variation.sku}
                                retailerOptionValue={item.value}
                                optionName={item.optionName}
                                errors={variation.validationErrors}
                            />
                        </div>
                    ))
                )}
            </div>
        ));
    };

    // render first level of option
    return Object.values(groupedVariations).map((item) => {
        if (item.variants) {
            return (
                <div
                    key={item.id}
                    style={{
                        border: `1px solid ${greyishBlue100}`,
                        borderRadius:'8px',
                        marginBottom: '12px',
                        padding: '12px 8px'
                    }}
                >
                    <VariationAccordion
                        retailerOptionValue={item.value}
                        optionName={item.optionName}
                        image={getFirstProductImage(item.pictures)}
                        variationCount={item.count}
                        sku={item.sku}
                        open={Object.values(item.variants).some(obj => obj.id === selectedVariation?.id)}
                    >
                        {/* then the rest of options */}
                        {renderVariants(item?.variants)}
                    </VariationAccordion>
                </div>
            )
        } else { // variation with only one option
            return (
                Object.values(item.variations).map((variation, index) => (
                    <div
                        onClick={() => switchVariation(variation.id)}
                        style={{
                            borderRadius: '8px',
                            marginBottom: '12px',
                            cursor: 'pointer',
                            padding: '12px 8px 12px 34px' ,
                            border: `1px solid ${greyishBlue100}`,
                            ...selectedVariation?.id === variation.id && {
                                border: `1px solid ${blueColor}`,
                                borderRadius: '4px',
                                backgroundColor: blue25
                            }
                        }}
                    >
                        <VariationResume
                            image={getFirstProductImage(variation.pictures)}
                            stock={variation.stock}
                            sku={variation.sku}
                            retailerOptionValue={item.value}
                            optionName={item.optionName}
                            errors={variation.validationErrors}
                        />
                    </div>
                ))
            )
        }
    });
}

export default VariationListing;
