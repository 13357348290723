import React, { Component } from 'react';
import {injectIntl} from "react-intl";
import Select from 'react-select'
import Cookies from "js-cookie";

// style
import {successColor, dangerColor} from "../../../../../assets/jss/main";

class MovementSelect extends Component {
    constructor(props) {
        super(props);

        this.movementOptions = [
            {
                value: 'decrement',
                label: this.props.intl.formatMessage({id:"stockeasy.manage.stock.remove"}),
                secondaryLabel: `(${this.props.intl.formatMessage({id:"stockeasy.manage.stock.exit"})})`,
                color: dangerColor
            },
            {
                value: 'increment',
                label: this.props.intl.formatMessage({id:"stockeasy.manage.stock.add"}),
                secondaryLabel: `(${this.props.intl.formatMessage({id:"stockeasy.manage.stock.entry"})})`,
                color: successColor
            },
        ]
    }

    handleChangeMovement(data) {
        if (this.props.customOnChange) {
            this.props.customOnChange(data)
        }

        Cookies.set("stockEasyMovement", data.value, {domain: process.env.REACT_APP_HOST, expires: 0.4, secure: true});
    }

    getDefaultValue(movementOptions) {
        let index = 0;

        if (Cookies.get("stockEasyMovement")) {
           index = movementOptions.map((action) => action.value).indexOf(Cookies.get("stockEasyMovement"));
        }

        return movementOptions[index]
    }

    render() {
        const secondaryLabel = (content) => ({
            alignItems: 'center',
            display: 'flex',
            ':after': {
                content: `'${content}'`,
                display: 'block',
                marginLeft: 4,
                color: 'grey',
                fontWeight: "normal"
            },
        });

        const colourStyles = {
            control: styles => ({ ...styles, backgroundColor: 'white' }),
            option: (styles, { data }) => ({
                ...styles,
                ...secondaryLabel(data.secondaryLabel),
            }),
            singleValue: (styles, { data }) => ({
                ...styles,
                color: data.color,
                fontWeight: "bold",
                ...secondaryLabel(data.secondaryLabel),
            }),
        };

        return (
            <Select
                defaultValue={this.getDefaultValue(this.movementOptions)}
                options={this.movementOptions}
                styles={colourStyles}
                onChange={(data) => this.handleChangeMovement(data)}
            />
        )
    }
}

export default (injectIntl(MovementSelect));
