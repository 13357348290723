import React, {useContext, useState} from 'react';
import PropTypes from "prop-types";
import {Form as FormikForm, Formik} from "formik";
import * as yup from "yup";
import {lazy} from "yup";
import mapValues from "lodash/mapValues";
import {FormattedMessage, injectIntl} from "react-intl";
import { useHistory } from 'react-router-dom';

import Grid from "@mui/material/Grid";

import PageContent from "components/page/PageContent";
import ActionsContainer from "components/containers/ActionsContainer";
import CancelButtonStyled from "components/button/CancelButtonStyled";
import SaveButton from "components/button/SaveButton";

import MainInformation from "./components/MainInformation";
import VariationOptions from "./components/VariationOptions";
import VariationReferences from "./components/VariationReferences";
import VariationPictures from "./components/VariationPictures";
import LockVariationButton from "./components/LockVariationButton";

import {removeFile} from "api/upload";
import update from "api/product/variations/update";
import create from "api/product/variations/create";
import createLock from "api/product/variations/lock/create";
import updateLock from "api/product/variations/lock/update";

import {ProductShowContext} from "../../context/ProductShowContext";

import getTranslationInitialValues from "utils/getTranslationInitialValues";
import {objectsHaveDifferentValues} from "utils/objectsHaveDifferentValues";
import {green500} from "../../../../../../assets/jss/main";
import StatusBadge from "../StatusBadge";

VariationForm.propTypes = {
    variation: PropTypes.shape({
        translations: PropTypes.object,
        stock: PropTypes.number,
        price: PropTypes.number,
        outletPrice: PropTypes.number,
        discountedPrice: PropTypes.number,
        ecoTax: PropTypes.number,
        weight: PropTypes.number,
        sku: PropTypes.string
    })
};

function VariationForm({variation, ...props}) {
    const {
        refreshProduct,
        product,
        selectedVariation,
        refreshSelectedVariationFormValues,
        optionsCount,
        variantsCombinaisons,
    } = useContext(ProductShowContext);

    const [picturesToDelete, setPicturesToDelete] = useState([]);
    const [submitted, setSubmitted] = useState(false);

    const history = useHistory();

    const validationSchema = yup.object({
        translations: lazy(obj => yup.object(
            mapValues(obj, () => yup.object({
                name: yup.string().required(props.intl.formatMessage({id: 'product.show.variation.form.field.name.error.required'}))
            }))
        )),
        stock: yup.number()
            .typeError(props.intl.formatMessage({id: 'product.show.variation.form.field.stock.error.type'}))
            .required(props.intl.formatMessage({id: 'product.show.variation.form.field.stock.error.required'}))
            .positive(props.intl.formatMessage({id: 'product.show.variation.form.field.stock.error.positive'}))
            .min(0),
        price: yup.number()
            .transform((value, originalValue) => replaceCommaWithDot(value, originalValue))
            .typeError(props.intl.formatMessage({id: 'product.show.variation.form.field.price.error.type'}))
            .required(props.intl.formatMessage({id: 'product.show.variation.form.field.price.error.required'}))
            .positive(props.intl.formatMessage({id: 'product.show.variation.form.field.price.error.positive'})),
        outletPrice: yup.number()
            .transform((value, originalValue) => replaceCommaWithDot(value, originalValue))
            .nullable()
            .typeError(props.intl.formatMessage({id: 'product.show.variation.form.field.outletPrice.error.type'}))
            .positive(props.intl.formatMessage({id: 'product.show.variation.form.field.outletPrice.error.positive'})),
        discountedPrice: yup.number()
            .transform((value, originalValue) => replaceCommaWithDot(value, originalValue))
            .nullable()
            .typeError(props.intl.formatMessage({id: 'product.show.variation.form.field.discountedPrice.error.type'}))
            .positive(props.intl.formatMessage({id: 'product.show.variation.form.field.discountedPrice.error.positive'})),
        ecoTax: yup.number()
            .transform((value, originalValue) => replaceCommaWithDot(value, originalValue))
            .nullable()
            .typeError(props.intl.formatMessage({id: 'product.show.variation.form.field.ecoTax.error.type'}))
            .positive(props.intl.formatMessage({id: 'product.show.variation.form.field.ecoTax.error.positive'})),
        weight: yup.number()
            .transform((value, originalValue) => replaceCommaWithDot(value, originalValue))
            .typeError(props.intl.formatMessage({id: 'product.show.variation.form.field.weight.error.type'}))
            .required(props.intl.formatMessage({id: 'product.show.variation.form.field.weight.error.required'}))
            .positive(props.intl.formatMessage({id: 'product.show.variation.form.field.weight.error.positive'})),
        sku: variation ?
            yup.string().required(props.intl.formatMessage({id: 'product.show.variation.form.field.sku.error.required'}))
            : yup.string().nullable(),
        retailerOptionValues: yup
            .array()
            .min(1, props.intl.formatMessage({id: 'product.show.variation.form.field.retailerOptionValues.error.min'}))
            .max(optionsCount, props.intl.formatMessage(
                {id: 'product.show.variation.form.field.retailerOptionValues.error.max'},
                {optionsCount:optionsCount}
            ))
            .test('checkIfCombinaisonVariantExist', (value, { createError, path }) => {
                const isExist = checkIfCombinaisonVariantExist(value);

                // validation fail
                if (isExist) {
                    return createError({
                        path,
                        message: props.intl.formatMessage({id: 'product.show.variation.form.field.retailerOptionValues.error.exist'}) });
                }

                // validation pass
                return true;
            }),
    });

    const replaceCommaWithDot = (value, originalValue) => {
        if (typeof originalValue === 'string') {
            return parseFloat(originalValue.replace(/,/g, '.'));
        }

        return value;
    }

    const initialValues = {
        'translations': variation?.translations || getTranslationInitialValues(),
        'sku': variation?.sku || '',
        'stock': variation?.stock ?? 0,
        'price': variation?.price || null,
        'outletPrice': variation?.outletPrice || null,
        'discountedPrice': variation?.discountedPrice || null,
        'weight': variation?.weight || null,
        'retailerOptionValues': orderRetailerOptionValuesByOptionPosition(variation?.retailerOptionValues) || [],
        'pictures': variation?.pictures || [],
        'unavailableBefore': variation?.unavailableBefore || null,
        'externalId': variation?.externalId || null,
        'ecoTax': variation?.ecoTax || null,
        'variationLock': variation?.variationLock || null,
        'status': variation?.status || false
    };

    function formatRetailerOptionValueId(id) {
        return `/retailer_option_values/${id}`
    }

    function checkIfCombinaisonVariantExist(newVariantCombinaison) {
        // remove selected variant combinaison from the variantsCombinaisons array
        // to avoid saying that the combination, before editing, already exists
        const variantsCombinaisonsCopy = removeSelectedVariantFromVariantsCombinaisons()

        // create array that contain only uuid of retailer option values
        const newVariantCombinaisonRovIds = createRovArrayIds(newVariantCombinaison);

        // find if new variant combinaison exist
        return variantsCombinaisonsCopy.some((variantsCombinaison) => {
            // check on variantsCombinaison that has same number of option
            if (variantsCombinaison.length === newVariantCombinaisonRovIds.length) {
                return newVariantCombinaisonRovIds.every((id) => variantsCombinaison.includes(id))
            }
        });
    }

    function createRovArrayIds(combinaison) {
        return combinaison.map((rov) => formatRetailerOptionValueId(rov.id));
    }

    function removeSelectedVariantFromVariantsCombinaisons() {
        if (selectedVariation === null) return variantsCombinaisons;

        const oldVariantCombinaison = selectedVariation.retailerOptionValues;
        const oldVariantCombinaisonRovIds = createRovArrayIds(oldVariantCombinaison);

        let variantsCombinaisonsCopy = [...variantsCombinaisons];

        const combinaisonIndex = variantsCombinaisonsCopy.findIndex((variantsCombinaison) => {
            return oldVariantCombinaisonRovIds.every((id) => variantsCombinaison.includes(id))
        });

        if (combinaisonIndex !== -1) {
            variantsCombinaisonsCopy.splice(combinaisonIndex, 1);
        }

        return variantsCombinaisonsCopy;
    }

    async function handleLock(variation, lockValues) {
        if (!lockValues) return;

        const initialLockValues = initialValues.variationLock;

        if (!initialLockValues?.['@id']) {
            await createLock(variation['@id'], lockValues)
        } else {
            if (objectsHaveDifferentValues(initialLockValues, lockValues)) {
                await updateLock(lockValues['@id'], lockValues);
            }
        }
    }

    async function deleteRemovedPictures() {
        for (const mediaObject of picturesToDelete) {
            await removeFile(mediaObject['@id']);
        }

        setPicturesToDelete([]);
    }

    function orderRetailerOptionValuesByOptionPosition(retailerOptionValues) {
        if (!retailerOptionValues) return;

        return retailerOptionValues.sort((a, b) => a.option.position - b.option.position);
    }

    async function onSubmit(values) {
       let promise;

       if (variation === null) {
           values['product'] = product['@id']
           promise = create(values);
       } else {
           promise = update(variation, values);
       }

       await promise.then(async (retrievedVariation) => {
           // delete from the mp the photos we have deleted from the slider
           await deleteRemovedPictures();

           await handleLock(retrievedVariation, values.variationLock);

           await refreshProduct().then((retrievedProduct) => {
               // sets the created variation as the selected variation (so that it can be modified)
               refreshSelectedVariationFormValues(retrievedProduct.variations, retrievedVariation.id)
           });
       })
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            validateOnChange={true}
            validateOnBlur={true}
            validateOnMount={true}
            onSubmit={async (values, {setSubmitting}) => {
                await onSubmit(values);
                setSubmitting(false);
                setSubmitted(true);
            }}
            onReset={() => setSubmitted(false)}
        >
            {({
                  isSubmitting,
                  isValid,
            }) => (
                <PageContent
                    titleId='product.show.variation.form.title'
                    subtitleId='product.show.variation.form.subtitle'
                    fullPage={true}
                    rightContent={(<LockVariationButton />)}
                    titleRightContent={(<StatusBadge status={initialValues.status} isVariation={true} />)}
                    paddingHeader={'24px 24px 0 24px'}
                    paddingBody={'48px 24px 24px 24px'}
                >
                    <FormikForm>
                        <Grid
                            item
                            container
                            direction='column'
                            xs={12}
                            rowSpacing={6}
                        >
                            <Grid item container>
                                <MainInformation />
                            </Grid>
                            <Grid item container>
                                <VariationOptions />
                            </Grid>
                            <Grid item container>
                                <VariationReferences />
                            </Grid>
                            <Grid item container>
                                <VariationPictures
                                    picturesToDelete={picturesToDelete}
                                    setPicturesToDelete={setPicturesToDelete}
                                />
                            </Grid>
                            <Grid item>
                                <ActionsContainer>
                                    <CancelButtonStyled
                                        disabled={isSubmitting}
                                        onClick={() => history.goBack()}
                                    >
                                        <FormattedMessage id='product.show.variation.form.actions.cancel' />
                                    </CancelButtonStyled>
                                    <SaveButton
                                        type='submit'
                                        disabled={isSubmitting || !isValid}
                                        loading={isSubmitting}
                                    >
                                        {selectedVariation !== null ? (
                                            <FormattedMessage id='product.show.variation.form.actions.update' />
                                        ) : (
                                            <FormattedMessage id='product.show.variation.form.actions.create' />
                                        )}
                                    </SaveButton>
                                </ActionsContainer>
                                {submitted &&
                                    <div style={{textAlign: "right", color: green500}}>
                                        <FormattedMessage id='product.show.variation.form.actions.update.success' />
                                    </div>
                                }
                            </Grid>
                        </Grid>
                    </FormikForm>
                </PageContent>
            )}
        </Formik>
    );
}

export default injectIntl(VariationForm);
