import React, {useContext, useEffect, useState} from 'react';
import {Draggable} from "react-beautiful-dnd";
import {FixedSizeList} from "react-window";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";

import DragAndDropContext from "components/dragAndDrop/DragAndDropContext";

import CreateBelow from "../../../configuration/components/CreateBelow";
import Form from "./components/Form";
import Header from "./components/Header";
import ItemLine from "./components/ItemLine";

import {ListContext} from "components/List/context/ListContext";

import list from "api/option/list";
import {updatePosition} from "api/option/updatePosition";

function Content(props) {
    const [lastPosition, setLastPosition] = useState(0);

    const [isElementBeingDragged, setIsElementBeingDragged] = useState(false);

    const {
        loading,
        setLoading,
        listItems,
        setListItems,
        selectedItem,
        setSelectedItem,
        setNotFilteredListItems,
        createBelow,
        showCreateBelow,
        hideCreateBelow,
        openCreateDialogViaCreateBelow,
        isCreateDialogOpen,
        closeCreateDialog,
        showErrorNotification,
        moveListItem,
    } = useContext(ListContext)

    // componentDidMount
    useEffect(() => {
        getOptions();
    }, []);

    function getOptions() {
        closeCreateDialog();

        setSelectedItem(null);

        setLoading(true);

        list().then((response) => {
            setListItems(response);
            setNotFilteredListItems(response);

            if (response.length > 0) {
                setLastPosition(response[response.length - 1].position)
            }
        }).catch(() => {
            showErrorNotification();
        }).finally(() => {
            setLoading(false);
        })
    }

    function moveOption(result) {
        if (!result.destination) {
            return;
        }

        setIsElementBeingDragged(false);

        // update on front
        moveListItem(result)

        // update on back
        const destinationOptionPosition = listItems[result.destination.index]?.position;

        if (!destinationOptionPosition) {
            return;
        }

        setLoading(true);

        updatePosition(result.draggableId, destinationOptionPosition)
            .then(() => getOptions())
            .catch(() => showErrorNotification())
            .finally(() => setLoading(false))
        ;
    }

    return (
        <Grid container>
            <Header />
            <Grid item container>
                <DragAndDropContext
                    onDragEnd={(result) => moveOption(result)}
                    onDragStart={() => setIsElementBeingDragged(true)}
                    droppableId={"optionList"}
                    items={listItems}
                    renderClone={(data) => <ItemLine option={data} />}
                >
                    {droppableProvided => (
                        <FixedSizeList
                            height={window.innerHeight * 0.79}
                            itemCount={listItems.length}
                            itemSize={58}
                            width={'100%'}
                            itemData={listItems}
                            innerRef={droppableProvided.innerRef}
                        >
                            {({data, index, style}) => {
                                const option = data[index];

                                if (!option) {
                                    return null;
                                }

                                return (
                                    <Grid
                                        item
                                        container
                                        key={option.id}
                                        onMouseEnter={(event) => {
                                            !isElementBeingDragged && showCreateBelow(event, option.id)
                                        }}
                                        onMouseLeave={() => !isElementBeingDragged && hideCreateBelow()}
                                        sx={{...style, padding: '12px 0'}}
                                    >
                                        <Draggable
                                            draggableId={option.id}
                                            index={index}
                                            isDragDisabled={loading}
                                        >
                                            {(provided, snapshot) => (
                                                <ItemLine
                                                    option={option}
                                                    isDragging={snapshot.isDragging}
                                                    innerRef={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                />
                                            )}
                                        </Draggable>
                                        <CreateBelow
                                            isVisible={option.id === createBelow.listItemId}
                                            onClick={() => openCreateDialogViaCreateBelow(option.position)}
                                            style={{ bottom: '-28px' }}
                                        />
                                    </Grid>
                                )
                            }}
                        </FixedSizeList>
                    )}
                </DragAndDropContext>
            </Grid>
            <Dialog
                open={isCreateDialogOpen}
                onClose={() => closeCreateDialog()}
                maxWidth={'sm'}
                scroll={'body'}
            >
                <DialogContent>
                    <Form
                        refreshList={getOptions}
                        selectedOption={selectedItem}
                        createBelow={createBelow}
                        lastPosition={lastPosition}
                    />
                </DialogContent>
            </Dialog>
        </Grid>
    );
}

export default Content;
