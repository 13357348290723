export function paramsToObject(params) {
    if (!params || params.length === 0) {
        return {};
    }

    // sometime to escape percent sign, we had a backslash before when encoding url
    // here, when decoding, whe have to remove it
    params = params
        .replace(/\\%/g, '%') // remove backslashes added before % signs
        .replace(/(\\)?%(?![0-9][0-9a-fA-F]+)/g, '%25'); // encode % signs

    params = new URLSearchParams(decodeURIComponent(params));

    let entries = params.entries();

    let result = {};

    for (let entry of entries) {
        let [key, value] = entry;

        if (value === "true") {
            result[key] = true;
        } else if (value === "false") {
            result[key] = false;
        } else {
            result[key] = value;
        }
    }

    return result;
}