import React, {useContext} from "react"
import PropTypes from "prop-types";
import classNames from "classnames";

import makeStyles from "@mui/styles/makeStyles";

import TableRow from "@mui/material/TableRow";

import StyledTableCell from "components/TableCells/StyledTableCell";
import TableCellColumn from "components/TableCells/TableCellColumn";

import Brand from "./components/Brand";
import LineItemActions from "./components/LineItemActions";
import LineItemEditAction from "./components/LineItemEditAction";
import Name from "./components/Name";
import Price from "./components/Price";
import CustomCheckbox from "components/checkbox/CustomCheckbox";
import PickingProductImage from "./components/PickingProductImage";
import RunnerAvatarContainer from "./components/RunnerAvatarContainer";
import StatusChip from "./components/StatusChip";

import LineItemAdjustmentRow from "./components/LineItemAdjustmentRow";

import {
    defaultFont,
    thirteenthGrey,
    veryLightBlue,
} from "assets/jss/main";

import {OrderContext} from "contexts/OrderContext";

import {LineItemStatus} from "utils/LineItemStatus";

const useStyles = makeStyles(theme => ({
    checkbox: {
        padding: 0,
        textAlign: 'right',
        borderBottom: 'unset',
        paddingLeft: '15px'
    },
    tableImageCell: {
        position: 'relative',
        '& span': {
            backgroundColor: 'white',
            zIndex: 1,
        },
    },
    tableCellRunner:{
        ...defaultFont,
        lineHeight: "1.42857143",
        verticalAlign: "middle",
        minWidth: "85px"
    },
    tableCellStatus: {
        textAlign: "center",
        width: '200px',
        maxWidth: '200px'
    },
    tableCellName: {
        minWidth: "200px",
        wordWrap: 'anywhere',
    },
    itemRow: {
        borderTop: `1px solid ${thirteenthGrey}`,
    },
    itemRowChecked: {
        background: veryLightBlue,
    },
    itemRowWithoutAdjustments: {
        borderBottom: `1px solid ${thirteenthGrey}`,
    },
    itemRowWithAdjustments: {
        '& td': {
            paddingBottom: 8,
        },
    },
    lastAdjustment: {
        borderBottom: `1px solid ${thirteenthGrey}`,
        '& td': {
            paddingBottom: 24,
        },
    }
}));

export default function LineItemRow({lineItem}) {
    const classes = useStyles();

    let pictures = [];
    if (!!lineItem.variation) {
        pictures = lineItem.variation.pictures;
    } else if (!!lineItem.pictures) {
        pictures = lineItem.pictures;
    }

    const {
        currentOrganization,
        authorizations,
        member,
        updateLoadingLineItem,
        runnerHeader,
        organizationShippingMethods,
        lineItemSelectedIds,
        handleChangeCheckboxItem,
        updateLoadingOrder
    } = useContext(OrderContext);

    const isLineItemChecked = lineItemSelectedIds.includes(lineItem['@id']);

    return (
        <>
            <TableRow className={classNames(
                classes.itemRow,
                {
                    [classes.itemRowChecked]: isLineItemChecked,
                    [classes.itemRowWithoutAdjustments]: lineItem.adjustments.length === 0,
                    [classes.itemRowWithAdjustments]: lineItem.adjustments.length !== 0,
                }
            )}>
                <StyledTableCell className={classes.checkbox}>
                    <CustomCheckbox
                        hidden={lineItem.status !== LineItemStatus.TO_SHIP || lineItem.shipments.length > 0}
                        checked={isLineItemChecked}
                        onChange={() => handleChangeCheckboxItem(lineItem['@id'])}
                    />
                </StyledTableCell>
                <StyledTableCell className={classes.tableImageCell}>
                    <PickingProductImage
                        pictures={pictures}
                        shippingMethodName={lineItem.shippingMethod}
                        organizationShippingMethods={organizationShippingMethods}
                    />
                </StyledTableCell>
                <StyledTableCell>
                    <TableCellColumn className={classes.tableCellName}>
                        <Name lineItem={lineItem} />
                    </TableCellColumn>
                </StyledTableCell>
                <StyledTableCell>
                    <TableCellColumn>
                        <Brand lineItem={lineItem}/>
                    </TableCellColumn>
                </StyledTableCell>
                <StyledTableCell>
                    <TableCellColumn>
                        <Price
                            price={lineItem.retailerPrice}
                            initialRecommendedPrice={lineItem.initialRecommendedPrice}
                        />
                    </TableCellColumn>
                </StyledTableCell>
                <StyledTableCell className={classes.tableCellRunner}>
                    {(runnerHeader && lineItem.runner) && (
                        <RunnerAvatarContainer lineItem={lineItem} />
                    )}
                </StyledTableCell>
                <StyledTableCell className={classes.tableCellStatus}>
                    <StatusChip
                        status={lineItem.status}
                        cancellationReason={lineItem.cancellationReason}
                        loading={updateLoadingOrder}
                    />
                </StyledTableCell>
                <StyledTableCell className={classes.tableCellStatus} padding="checkbox">
                    <LineItemActions
                        item={lineItem}
                        currentOrganization={currentOrganization}
                        authorizations={authorizations}
                        member={member}
                        updateLoadingLineItem={updateLoadingLineItem}
                    />
                </StyledTableCell>
                <StyledTableCell>
                    <LineItemEditAction lineItem={lineItem} />
                </StyledTableCell>
            </TableRow>
            {lineItem.adjustments.map((adjustment, index) => {
                return (
                    <LineItemAdjustmentRow
                        key={index}
                        label={adjustment['label']}
                        amount={adjustment['amount']}
                        isSponsored={adjustment['sponsored']}
                        className={classNames({
                            [classes.itemRowChecked]: isLineItemChecked,
                            [classes.lastAdjustment]: lineItem.adjustments.length === index + 1,
                        })}
                    />
                )
            })}
        </>
    )
}

LineItemRow.propTypes = {
    lineItem: PropTypes.shape({
        variation: PropTypes.object,
        pictures: PropTypes.array,
        status: PropTypes.string,
        shippingMethod: PropTypes.string,
        retailerPrice: PropTypes.number,
        initialRecommendedPrice: PropTypes.number,
        runner: PropTypes.object,
        cancellationReason: PropTypes.object,
        adjustments: PropTypes.array,
    }).isRequired,
};
