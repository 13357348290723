import {SubmissionError} from "redux-form";
import Cookies from "js-cookie";
import {fetch} from "../utils/dataAccess";

export function error(error) {
    return {type: "LOGIN_ERROR", error};
}

export function loading(loading) {
    return {type: "LOGIN_LOADING", loading};
}

export function success(userData) {
    return {type: "LOGIN_SUCCESS", userData};
}

const MAX_COOKIES_COUNT = 20;
export function getSessionOrganizations() {
    let organizations = Object.create(null);
    for (let i = 0; i <= MAX_COOKIES_COUNT; i++) {
        if (Cookies.get('_d' + i)) {
            let value = JSON.parse(Cookies.get('_d' + i))
            if (typeof value === 'undefined') {
                continue;
            }

            value.forEach((orga) => {
                organizations[orga.id] = orga;
            });
        }
    }

    return Object.keys(organizations).length !== 0 ? organizations : null;
}

export function putSessionOrganizations(session) {
    if (typeof session !== 'object') {
        console.warn('Trying to put session with a non object.');
        return;
    }

    for (let i = 0; i <= MAX_COOKIES_COUNT; i++) {
        Cookies.remove('_d' + i);
    }

    let cookieIndex = 0;
    const setACookie = (organizations) => {
        const content = JSON.stringify(organizations);
        Cookies.set('_d' + cookieIndex, content, {domain: process.env.REACT_APP_HOST, expires: 0.4, secure: true});
        cookieIndex++;
    };

    const COUNT_ORGAS_PER_COOKIE = 5;
    let count = 0;
    let organizationsToStore = [];
    Object.entries(session).forEach(([id, organization]) => {
        organizationsToStore.push(organization);
        count++;
        if (count === COUNT_ORGAS_PER_COOKIE) {
            setACookie(organizationsToStore);
            count = 0;
            organizationsToStore = [];
        }
    });

    if (organizationsToStore.length !== 0 ) {
        setACookie(organizationsToStore);
    }
}

export function login(values) {
    return (dispatch) => {
        dispatch(loading(true));

        return fetch("/login_check", {method: "POST", body: JSON.stringify(values)})
            .then(response => {
                return response.json();
            })
            .then(data => {
                this.putSessionOrganizations(data);

                dispatch(success(data));
                dispatch(loading(false));
            })
            .catch(e => {
                dispatch(loading(false));

                if (e instanceof SubmissionError) {
                    dispatch(error(e.errors._error));
                    throw e;
                }
                dispatch(error(e.message));
            });
    };
}

export function logout() {
    let protocol = window.location.protocol;
    let host = window.location.host;
    let parts = host.split(":");

    let url = "auth." + process.env.REACT_APP_HOST;

    // in-case we have a port number in the host url. (For dev environment)
    if (parts.length === 2) {
        url += ":" + parts[1]
    }

    Cookies.remove('_d', {domain: process.env.REACT_APP_HOST});
    window.location = protocol + "//" + url + "/login";

    return (dispatch) => {
        fetch("/logout", {method: "POST"})
            .then(response => {
                dispatch('LOGOUT_SUCCESS');
                return response.json();
            })
            .catch(e => {
                if (e instanceof SubmissionError) {
                    dispatch(error(e.errors._error));
                    throw e;
                }

                dispatch(error(e.message));
            });
    };

}
