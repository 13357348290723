import { container, defaultFont } from "../../main";

const listStyle = theme => ({
    containerWithSidebar: {
        [theme.breakpoints.up("md")]: {
            marginLeft: "240px",
        }
    },
    container: {
        ...container,
        ...defaultFont
    },
    headerFilters: {
        display: 'inline-block',
        margin: '20px 0',
        '@media (max-width: 950px)': {
            display: 'block',
            margin: '10px auto',
            width: '70%',
        },
    },
    retailerSelect: {
        width: '100%',
        paddingTop: '10px',
    },
    exportButton: {
        float: 'right',
    },
    flexBoxColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    infoBox: {
        display: "flex",
        alignItems: "center",
        border: '1px solid #dbdbdb',
        borderTop: 0,
        borderBottomRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        padding: '20px 10px 5px',
        marginTop: '-15px',
    },
    infoIconBox: {
        padding: '0 5px',
        marginRight: '15px',
        marginBottom: '-5px',
        fontSize: '1,4em',
    },
    infoIcon: {
        fontSize: '1.4em',
        color: '#0052cb',
    },
    infoMessage: {
        flex: "1 1 auto",
    },
    openOrderDetail: {
        cursor: "pointer",
    },
});

export default listStyle;
