import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";

MultiRetailerOrder.propTypes = {
    isMultiRetailer: PropTypes.bool
};

function MultiRetailerOrder({isMultiRetailer = false}) {

    if (isMultiRetailer) {
        return <b><FormattedMessage id={'picking.order.show.table.order.multiRetailer'} /></b>
    }

    return null
}

export default MultiRetailerOrder;
