import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {DateRangePicker} from "react-dates";

import {CircularProgress, Grid, MenuItem, Select,} from "@mui/material";
import {withStyles} from "@mui/styles";

import {ArrowDownward, Info, NotificationsActive} from "@mui/icons-material";
import moment from "moment";

import CircularIndeterminate from "../../../components/loaders/circularIndeterminate";
import Button from "../../../components/button/button";
import ListLoader from "../../configuration/components/listLoader";

import listStyle from "../../../assets/jss/views/accounting/listStyle";

import {Authorizations, hasAuthorization} from "../../../utils/authorizations";
import List from "./list";

// API
import {downloadAllPayoutsExport, downloadPayoutsExport, list as listPayouts} from "api/accounting/payouts/list"
import paymentAccountList from "api/paymentAccount/list";
import Snackbar from "../../../components/snackbar/snackbar";

const UNIT_INTERVAL_COUNT = 6;
const UNIT_INTERVAL_TYPE = 'months';

class Container extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: moment().subtract(14, 'days'),
            endDate: moment(),
            maxDate: moment(),
            minDate: moment().subtract(UNIT_INTERVAL_COUNT, UNIT_INTERVAL_TYPE),
            paymentAccount: null, // Selected payment account
            paymentAccounts: null, // The list of available paymentAccounts for the current user (to populate the select field)
            response: null,
            loading: false,
            fileExportLoading: false,
            fileExportAllLoading: false,
            fileExportedAll: false,
        };
    }

    componentDidMount() {
        const queryParameters = new URLSearchParams(this.props.history.location.search);
        if (!!queryParameters.get('paymentAccount') && !!queryParameters.get('from') && !!queryParameters.get('to')) {
            let startDate = moment(queryParameters.get('from'));
            let endDate = moment(queryParameters.get('to'));
            const countDaysBetweenStartEnd = endDate.diff(startDate, 'days');
            const maxDaysInPeriodAllowed = UNIT_INTERVAL_COUNT * 30.5;

            const now = moment();
            let minDate, maxDate;
            if (countDaysBetweenStartEnd > maxDaysInPeriodAllowed || endDate > now) {
                this.setState({
                    startDate: moment().subtract(14, 'days'),
                    endDate: moment(),
                    maxDate: moment(),
                    minDate: moment().subtract(UNIT_INTERVAL_COUNT, UNIT_INTERVAL_TYPE),
                    paymentAccount: queryParameters.get('paymentAccount'),
                }, this.updateUrl);
                return;
            }
            const splitDaysPart = (maxDaysInPeriodAllowed - countDaysBetweenStartEnd) / 2;
            minDate = startDate.clone().subtract(Math.round(splitDaysPart), 'days');
            maxDate = endDate.clone().add(Math.round(splitDaysPart), 'days');
            maxDate = maxDate > now ? now : maxDate;

            this.setState({
                startDate,
                endDate,
                minDate,
                maxDate,
                paymentAccount: queryParameters.get('paymentAccount'),
            });
        }

        if (
            hasAuthorization(this.props.authorizations, Authorizations.GLOBAL_ORGANIZATION_ESHOP_MANAGEMENT)
            || hasAuthorization(this.props.authorizations, Authorizations.GLOBAL_ORGANIZATION_ACCOUNTING_MANAGEMENT)
            || hasAuthorization(this.props.authorizations, Authorizations.PICKING_ACCOUNTING)
        ) {
            paymentAccountList(`order[name]=asc&pagination=true&itemsPerPage=500`).then((res) => {
                this.setState({
                    paymentAccounts: res
                })
            })
        } else {
            // Not handled use case, redirect user to homepage
            this.props.history.push('/');
            return;
        }

        this.loadPayouts();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.state.startDate !== prevState.startDate
            || this.state.endDate !== prevState.endDate
            || (
                (prevState.paymentAccount === null && this.state.paymentAccount !== null) ||
                (prevState.paymentAccount !== this.state.paymentAccount)
            )
        ) {
            this.loadPayouts();
            this.setState({fileExported: false})
        }
    }

    updateUrl() {
        const { startDate, endDate, paymentAccount } = this.state;

        if (startDate === null || endDate === null || paymentAccount === null) {
            return;
        }

        let newParams = `?paymentAccount=${paymentAccount}`;
        newParams += `&from=${startDate.format("YYYY-M-D")}`;
        newParams += `&to=${endDate.format("YYYY-M-D")}`;

        this.props.history.push(`/accounting${newParams}`);
    }

    displayPaymentAccountsSelect() {
        const { intl, classes } = this.props;
        const { paymentAccount, paymentAccounts } = this.state;

        if (!paymentAccounts) {
            return;
        }

        const paymentAccountOptions = paymentAccounts.map((paymentAccount) => {
            return {
                value: paymentAccount.id,
                label: paymentAccount.name,
            };
        });

        // default paymentAccount selected
        if (!paymentAccount && paymentAccountOptions.length > 0) {
            this.setState({
                paymentAccount: paymentAccountOptions[0].value,
            });
        }

        if (paymentAccountOptions.length === 1) {
            return; // no need to display the select if the user have only one paymentAccount
        }

        return (
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <Select
                        variant="standard"
                        id="retailers"
                        className={classes.retailerSelect}
                        label={intl.formatMessage({id: "accounting.payout_list.retailer_select.label"})}
                        value={this.state.paymentAccount}
                        onChange={(event) => this.setState({paymentAccount: event.target.value}, this.updateUrl)}>
                        {paymentAccountOptions.map(option => (
                            <MenuItem value={option.value} key={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={4} textAlign={'right'}>
                    <Button
                        color={"info"} simple
                        disabled={this.state.fileExported}
                        onClick={() => this.downloadAllExport()}
                    >
                        {this.state.fileExportAllLoading ?
                            <div><CircularProgress size={20}/></div> :
                            <><ArrowDownward/> <FormattedMessage id={"accounting.payouts.download.all"}/></>
                        }
                    </Button>
                </Grid>
            </Grid>
        );
    }

    displayDateRangeMessage() {
        const { intl, classes } = this.props;

        return (
            <div className={classes.infoBox}>
                <div className={classes.infoIconBox}>
                    <Info className={classes.infoIcon}/>
                </div>
                <div className={classes.infoMessage}>
                    {intl.formatMessage({id: "accounting.payout_list.datepicker.max_period_info"})}<br/>
                    {intl.formatMessage({id: "accounting.payout_list.datepicker.max_period_info_example"})}
                </div>
            </div>
        )
    }

    displayDateRangePicker() {
        const { intl } = this.props;

        return (
            <DateRangePicker
                startDateId="startDate"
                endDateId="endDate"
                startDatePlaceholderText={intl.formatMessage({id: "accounting.payout_list.datepicker.from"})}
                endDatePlaceholderText={intl.formatMessage({id: "accounting.payout_list.datepicker.to"})}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                isOutsideRange={(day) => {
                    if (null === this.state.startDate && null === this.state.endDate) {
                        const now = moment();
                        return day > now;
                    }
                    return day < this.state.minDate || day > this.state.maxDate;
                }}
                onDatesChange={({startDate, endDate}) => {
                    const now = moment();
                    if (null === startDate && null === endDate) { // User clicked on X to clear the input
                        this.setState({minDate: null, maxDate: now, startDate: null, endDate: null}, this.updateUrl);
                        return;
                    }

                    let maxDate = this.state.maxDate;
                    if (startDate !== null && startDate !== this.state.startDate) {
                        maxDate = startDate.clone().add(UNIT_INTERVAL_COUNT, UNIT_INTERVAL_TYPE);
                        maxDate = maxDate > now ? now : maxDate;
                    }

                    let minDate = this.state.minDate;
                    if (endDate !== null && endDate !== this.state.endDate) {
                        minDate = endDate.clone().subtract(UNIT_INTERVAL_COUNT, UNIT_INTERVAL_TYPE);
                    }
                    this.setState({startDate, endDate, minDate, maxDate})
                }}
                focusedInput={this.state.focusedInput}
                onFocusChange={(focusedInput) => {
                    this.setState({focusedInput})
                }}
                onClose={(dates) => this.setState({startDate: dates.startDate, endDate: dates.endDate}, this.updateUrl)}
                displayFormat={"D MMMM YYYY"}
                block={true}
                readOnly={true}
                showDefaultInputIcon={true}
                showClearDates={true}
                reopenPickerOnClearDates={true}
                required={true}
                hideKeyboardShortcutsPanel={true}
                withFullScreenPortal={window.innerWidth < 768}
                numberOfMonths={2}
                orientation={window.innerWidth < 768 ? "vertical" : "horizontal"}
                anchorDirection={"left"}
                minimumNights={0}
            />
        );
    }

    downloadExport() {
        const {startDate, endDate, paymentAccount} = this.state;

        if (startDate === null || endDate === null || paymentAccount === null) {
            return;
        }

        this.setState({fileExportLoading: true});

        downloadPayoutsExport(paymentAccount, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
            .then(() => this.setState({fileExportLoading: false}))
        ;
    }

    downloadAllExport() {
        const {startDate, endDate} = this.state;

        if (startDate === null || endDate === null) {
            return;
        }

        this.setState({fileExportAllLoading: true});

        downloadAllPayoutsExport(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
            .then(() => this.setState({fileExportAllLoading: false, fileExported: true}))
        ;
    }

    loadPayouts() {
        const {startDate, endDate, paymentAccount} = this.state;

        if (startDate === null || endDate === null || paymentAccount === null) {
            return;
        }

        this.setState({loading: true});

        listPayouts(paymentAccount, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
            .then(response => this.setState({response: response}))
            .catch(e => console.warn('an unexpected error occurred', e))
            .finally(() => this.setState({loading: false}))
        ;
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                <div>
                    {this.displayPaymentAccountsSelect()}
                    <div className={classes.headerFilters}>
                        <div className={classes.flexBoxColumn}>
                            {this.displayDateRangePicker()}
                            {this.displayDateRangeMessage()}
                        </div>
                    </div>
                </div>
                <div>
                    <Button
                        color={"info"} simple
                        disabled={this.state.fileExportLoading}
                        onClick={() => this.downloadExport()}
                        className={classes.exportButton}
                    >
                        {this.state.fileExportLoading ?
                            <CircularIndeterminate/> :
                            <ArrowDownward/>
                        }
                        <FormattedMessage id={"accounting.payouts.download"}/>
                    </Button>
                    { (!!this.state.response && !this.state.loading) && (
                        <List
                            rows={this.state.response.rows}
                            totals={this.state.response.totals}
                            currentOrganization={this.props.currentOrganization}
                        />
                    )}
                    <ListLoader loading={this.state.loading}/>
                </div>

                    <Snackbar
                        open={this.state.fileExported}
                        close
                        closeNotification={() => this.setState({fileExported: false})}
                        place={"br"}
                        color={"success"}
                        icon={function () {
                            return <NotificationsActive/>
                        }}
                        message={<FormattedMessage id={"accounting.payouts.download.success"}/>}
                    />

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authorizations: state.authentication.authorizations,
        member: state.authentication.member,
        currentOrganization: state.currentOrganization.retrieved ?? null
    };
};

export default connect(
    mapStateToProps,
    null
)(withStyles(listStyle)(injectIntl(Container)));
