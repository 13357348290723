import {SubmissionError} from "redux-form";
import {fetch, normalize} from "../../utils/dataAccess";
import Cookies from "js-cookie";
import {getMemberRetailers} from "../authentificationMember";

export function error(error) {
    return {type: "PROFILE_SHOW_ERROR", error};
}

export function loading(loading) {
    return {type: "PROFILE_SHOW_LOADING", loading};
}

export function success(retrieved) {
    return {type: "PROFILE_SHOW_SUCCESS", retrieved};
}

export function updateError(updateError) {
    return {type: "PROFILE_UPDATE_UPDATE_ERROR", updateError};
}

export function updateLoading(updateLoading) {
    return {type: "PROFILE_UPDATE_UPDATE_LOADING", updateLoading};
}

export function updateSuccess(updated) {
    return {type: "PROFILE_UPDATE_UPDATE_SUCCESS", updated};
}

export function updateUserData(member) {
    return {type: "PROFILE_UPDATE_USER_DATA_MEMBER_SUCCESS", member};
}

export function retrieve(id) {
    return dispatch => {
        dispatch(loading(true));

        return fetch(`/users/${id}`, {method: "GET"})
            .then(response => {
                dispatch(loading(false));

                return response.json();
            })
            .then(retrieved => dispatch(success(retrieved)))
            .catch(e => {
                dispatch(loading(false));
                dispatch(error(e.message));
            });
    };
}

export function update(item, values) {
    return dispatch => {
        dispatch(updateError(null));
        dispatch(updateLoading(true));

        return fetch(item["@id"], {
            method: "PUT",
            headers: new Headers({"Content-Type": "application/ld+json"}),
            body: JSON.stringify(values)
        })
        .then(response =>
            response.json()
        )
        .then((retrieved) => {
            retrieved = normalize(retrieved);

            dispatch(updateLoading(false));

            let member = JSON.parse(Cookies.get("_c").toString());
            member["email"] = retrieved["email"];
            member["familyName"] = retrieved["familyName"];
            member["givenName"] = retrieved["givenName"];
            member["organization"] = retrieved["currentOrganization"].replace('/organizations/', '');
            member.retailers = getMemberRetailers();
            Cookies.set( "_c", JSON.stringify(member), {domain: process.env.REACT_APP_HOST, expires: 0.4, secure: true});

            dispatch(updateUserData(member));
            dispatch(updateSuccess(retrieved));
        })
        .catch(e => {
            dispatch(updateLoading(false));

            if (e instanceof SubmissionError) {
                dispatch(updateError(e.errors._error));
                throw e;
            }

            dispatch(updateError(e.message));
        });
    };
}

