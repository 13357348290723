import {fetch} from "../../../utils/dataAccess";
import downloadFileFromString from "../../../utils/createDownloadableFile";

export function list(paymentAccountId, startDate, endDate) {
    const query = `paymentAccount=${paymentAccountId}&interval[from]=${startDate}&interval[to]=${endDate}`
    return new Promise((resolve, reject) => {
        return fetch(`/retailer/stripe/payouts?${query}`).then(response => {
            response.json().then(retrieved => resolve(retrieved))
        }).catch(e => reject(e));
    });
}

export function downloadPayoutsExport(paymentAccount, startDate, endDate) {
    const query = `paymentAccount=${paymentAccount}&interval[from]=${startDate}&interval[to]=${endDate}`
    const headers = new Headers();
    headers.set('Accept', 'text/csv');

    const filename = `payouts-export-${startDate}-${endDate}-${paymentAccount}.csv`;

    return new Promise((resolve, reject) => {
        fetch(`/retailer/stripe/payouts?${query}`, {headers: headers})
            .then(response => response.text())
            .then(body => resolve(downloadFileFromString(filename, body, 'text/csv')))
            .catch(e => {
                console.warn('An error occured while downloading csv file', e);
                reject(e);
            })
        ;
    });
}

export function downloadAllPayoutsExport(startDate, endDate) {
    const query = `interval[from]=${startDate}&interval[to]=${endDate}`

    return new Promise((resolve, reject) => {
        fetch(`/retailer/stripe/payouts/all?${query}`, {method: "GET"})
            .then(response => {
                response.json().then(response => {
                    resolve(response);
                });
            })
            .catch(error => reject(error));
    });
}
