import React from 'react';
import PropTypes from 'prop-types';

import {DragDropContext, Droppable} from "react-beautiful-dnd";

DragAndDropContext.propTypes = {
    onDragEnd: PropTypes.func,
    onDragStart: PropTypes.func,
    droppableId: PropTypes.string.isRequired,
    items: PropTypes.array,
    renderClone: PropTypes.func
};

function DragAndDropContext({onDragEnd, onDragStart = () => {}, droppableId, items = [], renderClone = () => {}, ...props}) {
    return (
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            <Droppable
                droppableId={droppableId}
                mode="virtual"
                renderClone={(provided, snapshot, source) => (
                    <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                    >
                        {renderClone(items[source.source.index])}
                    </div>
                )}
            >
                {props.children}
                {/*
                    it's possible to create a custom reserved space,
                    but this is far too complex and is not implemented in the library
                    see: https://github.com/atlassian/react-beautiful-dnd/issues/518#issuecomment-617082552
                 */}
            </Droppable>
        </DragDropContext>
    );
}

export default DragAndDropContext;
