import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

import {makeStyles} from '@mui/styles';

import {ReactComponent as AddButtonBlue} from 'assets/img/add-button-blue.svg';

import {blueColor, blue50} from 'assets/jss/main';

const useStyles = makeStyles(theme => ({
    button: {
        display: 'inline-flex',
        padding: '0px 12px 0px 4px',
        alignItems: 'center',
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#ffffff',
        borderRadius: '48px',
        textAlign: 'center',
        border: 0,
        boxShadow: '0px 4px 22px 0px rgba(0, 0, 0, 0.08)',
        color: blueColor,
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        zIndex: 10,
        cursor: 'pointer',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: blue50,
        },
        /*
            add "bottom: -28px" when the style of the gender list is done
            and remove it from option and optionValue list
        */
    },
}));

CreateBelow.propTypes = {
    onClick: PropTypes.func,
    isVisible: PropTypes.bool
};

export default function CreateBelow({onClick, isVisible, ...props}) {
    const classes = useStyles();

    if (!isVisible) return null;

    return (
        <button
            type={'button'}
            className={classes.button}
            onClick={onClick}
            {...props}
        >
            <AddButtonBlue />
            <FormattedMessage id={'configuration.form.create-below'} />
        </button>
    );
}
