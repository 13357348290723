import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import Grid from "@mui/material/Grid";

import DragAndDropIcon from "components/icons/DragAndDropIcon";
import ItemName from "components/List/components/ItemName";
import Date from "components/List/components/Date";
import Hour from "components/List/components/Hour";
import IconButton from "components/button/IconButton";
import EditIcon from "components/icons/EditIcon";
import StyledLine from "components/List/components/StyledLine";
import {ListContext} from "components/List/context/ListContext";

import {getTranslation} from "domain/helpers/translations";

ItemLine.propTypes = {
    option: PropTypes.object.isRequired
};

function ItemLine({option, ...props}) {
    const {openCreateDialog} = useContext(ListContext)

    return (
        <StyledLine
            justifyContent={"space-between"}
            alignItems={"center"}
            { ...props}
        >
            <Grid
                item
                container
                xs={6}
                columnSpacing={2}
                flexWrap={"nowrap"}
            >
                <Grid
                    item
                    alignSelf={"center"}
                    display={"flex"}
                >
                    <DragAndDropIcon />
                </Grid>
                <Grid item zeroMinWidth>
                    <ItemName label={getTranslation(option).name} />
                </Grid>
            </Grid>
            <Grid
                item
                container
                xs={6}
                columnSpacing={2}
                justifyContent={"flex-end"}
                alignItems={"center"}
                flexWrap={"nowrap"}
            >
                <Grid item sx={{ width: '100px' }}>
                    <Date date={option.updatedAt} />
                </Grid>
                <Grid item sx={{ width: '60px' }}>
                    <Hour date={option.updatedAt} />
                </Grid>
                <Grid
                    item
                    container
                    justifyContent={"flex-end"}
                    sx={{ width: '80px' }}
                >
                    <Grid item>
                        <IconButton onClick={() => openCreateDialog(option)}>
                            <EditIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </StyledLine>
    );
}

export default ItemLine;
