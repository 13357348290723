export function parseErrors(errorString) {
    if (!errorString) {
        return [];
    }

    const regex = /Object\(.*?\)(?:\.(\w+))?:\s(.+)/g;
    const errors = [];
    let match;

    while ((match = regex.exec(errorString)) !== null) {
        const field = match[1] ? `${match[1]}: ` : "";
        let message = match[2].trim();

        message = message.replace(/\s\(code\s[^\)]+\)/, "").trim();
        message = message.replace(/\s\(debug\,\s[^\)]+\)/, "").trim();

        errors.push(`${field}${message}`);
    }

    return errors;
}